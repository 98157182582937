import { forwardRef } from "react";
import { IoIosSend } from "react-icons/io";
import styled from "styled-components";

import { ButtonProps } from "@/utils/types";

const Button = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease-out, width 0.5s ease-out,
      height 0.5s ease-out;
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      &::after {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  @media (hover: none) {
    &:not(:disabled):active {
      &::after {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #e1eef3;
  }
`;

const StyledIoIosSend = styled(IoIosSend)`
  color: rgba(55, 112, 255, 1);
  width: 22px;
  height: 22px;
`;

export const PitaliyButtonSend = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    onButtonDown: () => void;
    isMicRecording: boolean;
  }
>(({ className, isMicRecording, onClick, onMouseDown, onButtonDown }, ref) => {
  const handleButtonDown = () => {
    onButtonDown();
  };

  return (
    <Button
      className={className}
      type="button"
      disabled={isMicRecording}
      ref={ref}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onPointerDown={handleButtonDown}
      onTouchStart={handleButtonDown}
    >
      <StyledIoIosSend />
    </Button>
  );
});

import { useState, useEffect } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import styled from "styled-components";

import { ButtonModal } from "@/components/ButtonModal";

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 358px;
  height: 281px;
  border-radius: 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.95);

  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.6s cubic-bezier(0.56, 0, 0.43, 1.33) 0.15s,
    transform 0.6s cubic-bezier(0.56, 0, 0.43, 1.33) 0.15s;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.animating ${ModalWrapper} {
    opacity: 1;
    transform: scale(1);
  }
`;

const CancelRoundButton = styled.button`
  position: absolute;

  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #363636;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease-out, width 0.5s ease-out,
      height 0.5s ease-out;
  }

  @media (hover: hover) {
    &:hover {
      &::after {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  @media (hover: none) {
    &:active {
      &::after {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
`;

const CancelRoundIcon = styled(RxCross2)`
  color: rgba(162, 162, 162, 1);
`;

const InfoIcon = styled(IoMdInformationCircleOutline)`
  width: 26.67px;
  height: 26.67px;

  gap: 0px;
  opacity: 0px;
  color: rgba(55, 112, 255, 1);
`;

const InfoText = styled.p`
  margin-top: 4px;
  color: rgba(28, 28, 28, 1);
  font-weight: 500;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 24px 0;

  .cancelButton {
    margin-right: 4px;
  }

  .resetButton {
    margin-left: 4px;
    background: rgba(55, 112, 255, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
  }
`;

type Props = {
  isVisible: boolean;
  onCancel: () => void;
  onClick: () => void;
};

export function ResetConfirmModal({
  isVisible,
  onCancel,
  onClick,
}: Props): JSX.Element {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible) {
      // モーダルが表示される時、アニメーションをリセットして再開
      setIsAnimating(false);
      setTimeout(() => setIsAnimating(true), 50);
    } else {
      setIsAnimating(false);
    }
  }, [isVisible]);

  const overlayClass = `${isVisible ? "show" : ""} ${
    isAnimating ? "animating" : ""
  }`;

  return (
    <Overlay className={overlayClass}>
      <ModalWrapper>
        <CancelRoundButton onClick={onCancel}>
          <CancelRoundIcon />
        </CancelRoundButton>
        <InfoIcon />
        <InfoText>
          会話を初めからやり直します。
          <br />
          よろしいですか？
        </InfoText>
        <ButtonWrapper>
          <ButtonModal
            className="cancelButton"
            buttonType="cancel"
            onClick={onCancel}
          />
          <ButtonModal
            className="resetButton"
            buttonType="reset"
            onClick={onClick}
          />
        </ButtonWrapper>
      </ModalWrapper>
    </Overlay>
  );
}
